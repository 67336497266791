import {
    selectSubscribeModal,
    toggleSubscribeModal
} from '../../redux/reducers/generic/billing';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Components/Modal';
import PricingSection from '../Pricing/pricing-section';
import { getSoloSubscriptionLinks } from '../../redux/actions/solo/getSoloStripeLink';
import { selectSubscriptionLinks } from '../../redux/reducers/generic/profile';
import { useProject } from '../../context-providers/Project';

export const SubscribeModal = () => {
    const open = useSelector(selectSubscribeModal);
    const dispatch = useDispatch();
    const subscriptionLinks = useSelector(selectSubscriptionLinks);
    const { project } = useProject();

    async function getSubscriptionLinks() {
        await dispatch(getSoloSubscriptionLinks(project?.userEmail));
    }
    useEffect(() => {
        if (open) {
            getSubscriptionLinks();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={() => dispatch(toggleSubscribeModal())}
            maxWidth="max-w-6xl dark"
            bgColor="bg-black"
        >
            <PricingSection
                subscriptionLinks={subscriptionLinks}
                type={'solo'}
            />
        </Modal>
    );
};
