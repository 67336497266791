import { useEffect, lazy, Suspense } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { setRedirectURL } from '../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import HeroExperience from './HeroExperience';
import { useState } from 'react';
const SphereMask = lazy(() => import('../Components/magicui/sphere-mask'));
const CtaSection = lazy(() => import('./cta-section'));
const FeaturesSection = lazy(() => import('./FeaturesSection'));
const HeroSection = lazy(() => import('./hero-section'));
const ParticlesEffect = lazy(() => import('./HeroExperience/ParticlesEffect'));
const FAQ = lazy(() => import('./faq'));
const TestimonialSection = lazy(() => import('./TestimonialsSection'));
const PartnerSection = lazy(() => import('./PartnerSection'));
const PricingSection = lazy(() => import('../Pricing/pricing-section'));
const Journey = lazy(() => import('./Journey'));
const RadialGradient = lazy(
    () => import('../Components/magicui/RadialGradient')
);

export const LandingPage = () => {
    const sessionContext = useSessionContext();
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                try {
                    const element = document.querySelector(hash);
                    element.scrollIntoView();
                } catch (error) {
                    console.error(error);
                }
            }, 200);
        }
    }, [hash]);

    const onGetStarted = () => {
        let redirectURL = '/projects';
        if (enterpriseOrganizations.length > 0) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate('/auth/?redirectToPath=%2Fprojects');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const shouldLoad = scrollPosition > 0;

    return (
        <div className="bg-black">
            <div className="max-w-screen-xl mx-auto">
                <Suspense fallback={null}>
                    <RadialGradient />
                </Suspense>
            </div>
            <HeroExperience />

            <div className="dark max-w-screen-xl mx-auto">
                <Suspense fallback={null}>
                    <div className="min-h-[700px]">
                        {shouldLoad && <PartnerSection />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[400px]">
                        {shouldLoad && (
                            <HeroSection onGetStarted={onGetStarted} />
                        )}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[2000px]">
                        {shouldLoad && <Journey />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[1500px]">
                        {shouldLoad && <FeaturesSection />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[1000px]">
                        {shouldLoad && <PricingSection />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <SphereMask />
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[100px]">
                        {shouldLoad && <TestimonialSection />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[1000px]">
                        {shouldLoad && <FAQ />}
                    </div>
                </Suspense>

                <Suspense fallback={null}>
                    <div className="min-h-[400px]">
                        {shouldLoad && <CtaSection />}
                    </div>
                </Suspense>
            </div>

            <div className="fixed">
                <Suspense fallback={null}>
                    <ParticlesEffect />
                </Suspense>
            </div>
        </div>
    );
};

export default LandingPage;
