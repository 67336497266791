import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { selectProject } from './redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserIp } from './redux/actions/solo/updateUserIP';
import { selectEnterpriseOrganization } from './redux/reducers/enterprise/enterpriseOrganization';

export const AnalyticsWrapper = ({ children }) => {
    const sessionContext = useSessionContext();
    const [searchParams] = useSearchParams();
    const [selineInstance, setSelineInstance] = useState(null);
    const project = useSelector(selectProject);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadSeline = async () => {
            // setTimeout(async () => {
            if (!window.seline) {
                const seline = await import('@seline-analytics/web');
                window.seline = seline;
                setSelineInstance(seline);
                seline.init();
            } else {
                setSelineInstance(window.seline);
            }
            // }, 2000);
        };

        const adParams = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term',
            'gad_source',
            'gad_medium',
            'gad_campaign',
            'gad_content',
            'gad_term',
            'gclid'
        ];
        let adParamsObj = {};
        const existingParams = localStorage.getItem('ad_params');
        if (existingParams) {
            adParamsObj = JSON.parse(existingParams);
        }

        if (searchParams.get('ref')) {
            localStorage.setItem('referralLink', searchParams.get('ref'));
        }

        adParams.forEach(param => {
            if (searchParams.get(param)) {
                adParamsObj[param] = searchParams.get(param);
            }
        });
        if (Object.keys(adParamsObj).length > 0) {
            localStorage.setItem('ad_params', JSON.stringify(adParamsObj));
        }

        loadSeline();
    }, []);

    useEffect(() => {
        if (selineInstance && sessionContext?.accessTokenPayload?.email) {
            console.log(
                'Setting user',
                sessionContext.accessTokenPayload.email
            );
            dispatch(updateUserIp(enterpriseOrganization?._id));
            selineInstance.setUser({
                userId: sessionContext.accessTokenPayload.userId,
                email: sessionContext.accessTokenPayload.email,
                name: sessionContext.accessTokenPayload.email
            });
        } else if (project) {
            console.log('Setting user based on project', project.userEmail);
            selineInstance.setUser({
                email: project.userEmail
            });
        }
    }, [
        selineInstance,
        sessionContext.accessTokenPayload,
        project,
        enterpriseOrganization
    ]);

    return <>{children}</>;
};
