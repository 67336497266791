import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import autosize from 'autosize';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import { useProject } from '../../../../../context-providers/Project';
import { handleChatSubmit } from '../../../../../redux/actions/solo/handleChatSubmit';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useSubscriptionPayment } from '../../../../../context-providers/SubscriptionPayment';

export const Input = ({
    isSolo,
    isWidget,
    image_url,
    setImageUrl,
    fileKeyS3,
    setFileKeyS3,
    setFile,
    setProgress,
    isUploading,
    textareaRef,
    answer,
    isShare,
    setAnswer
}) => {
    let { chatHistory, projectId } = useProject();
    let { isSubbed } = useSubscriptionPayment();

    let waiting =
        chatHistory.waitingForChatResponse || chatHistory.waitingForSuggestion;
    let chat = chatHistory.data;

    const { accessTokenPayload } = useSessionContext();

    const dispatch = useDispatch();

    const [params] = useSearchParams();
    const username = params.get('username');

    const onChange = e => {
        setAnswer(e.target.value);
        autosize(document.querySelectorAll('textarea'));
    };

    // various effects for the chatinput
    useEffect(() => {
        if (!waiting && textareaRef && textareaRef.current) {
            autosize(textareaRef);
            if (window.innerWidth > 1024) textareaRef.current.focus(); // Refocus the textarea
        }
    }, [chat, waiting]);

    const soloNoGo = isSolo && !isSubbed && chat.length > 10;
    const enterpriseNoGo = !isSolo && !isSubbed && chat.length > 10;

    // define submit event handler
    const handleAnswerSubmit = async (event, ta) => {
        event.preventDefault();

        if (isShare) {
            toast.error('Please login to continue chatting');
            return;
        }

        if (soloNoGo) {
            toast.warning('Upgrade Account to continue chatting');
            return;
        }

        if (enterpriseNoGo) {
            toast.warning('Upgrade Account to continue chatting');
            return;
        }

        if (!answer) answer = document.getElementById('answer').value;

        if ((answer === '' || !answer) && !fileKeyS3 && !image_url) return;
        if (waiting) return;

        try {
            dispatch(
                handleChatSubmit({
                    answer,
                    image_url,
                    fileKeyS3,
                    projectId,
                    userEmail: accessTokenPayload?.email,
                    username,
                    isEnterprise: !isSolo,
                    isWidget
                })
            );
            console.log('dispatched');
            setAnswer('');
            setFile(null);
            setFileKeyS3(null);
            setImageUrl(null);
            setProgress(0);

            autosize.destroy(document.querySelectorAll('textarea'));
            textareaRef.current.style.height = '40px';
        } catch (err) {
            console.log(err);
            toast.error('Error submitting answer');
        }
    };

    return (
        <form onSubmit={handleAnswerSubmit}>
            <div
                className={`mt-3 py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
            >
                <div className="flex flex-row items-center">
                    <TextArea
                        id="answer"
                        rows="1"
                        ref={textareaRef}
                        onChange={onChange}
                        value={answer}
                        disabled={waiting}
                        className="min-h-[40px] max-h-[150px] resize-none overflow-y-auto px-0 w-full text-base text-gray-900 border-0 focus:ring-0 dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                        placeholder={
                            waiting ? 'Responding...' : 'Send a message'
                        }
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                handleAnswerSubmit(e, 'ta');
                            }
                        }}
                    />
                    <button
                        type="submit"
                        disabled={waiting || isUploading}
                        className={`text-white h-10 font-medium rounded-lg text-sm px-4 py-2 ml-2 text-center ${
                            waiting ||
                            isUploading ||
                            (answer === '' && !fileKeyS3 && !image_url)
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                        }`}
                    >
                        <PaperAirplaneIcon className="w-5 h-5" />
                    </button>
                </div>
            </div>
        </form>
    );
};
