import { useState, useEffect, useRef } from 'react';
import { submitHeroExperience } from '../../../redux/actions/solo/submitHeroExperience';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { getProjectByLink } from '../../../redux/actions/solo/getProjectByLink';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { PlaceholdersAndVanishInput } from '../../Components/magicui/cool-input';
import { placeholders } from './random_ideas';
import { updateProjectWithEmail } from '../../../redux/actions/solo/updateProjectWithEmail';
import { setRedirectURL } from '../../../constants';
import trackEvent from '../../../helpers/trackEvent';
export const InputArea = ({
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex,
    matchWithDev,
    urgency,
    tempProjectId,
    setTempProjectId
}) => {
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [fileKeyS3, setFileKeyS3] = useState(null);
    const [file, setFile] = useState(null);
    const [budget, setBudget] = useState(10000);
    const { accessTokenPayload } = useSessionContext();
    const userEmail = accessTokenPayload?.email;
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (userEmail && questionIndex === 3) setAnswer(userEmail);
    }, [questionIndex, userEmail]);

    const processFirstAnswer = async answer => {
        setAnswers(prevAnswers => [...prevAnswers, answer]);
        const project_id = await dispatch(
            submitHeroExperience({
                answers: [answer],
                userEmail: null,
                matchWithDev,
                budget,
                urgency,
                fileKeyS3
            })
        );
        trackEvent('firstHeroQuestionAnswered', {
            userEmail,
            projectId: project_id
        });
        setSearchParams({ redirectToPath: `/projects/${project_id}` });
        setRedirectURL(`/projects/${project_id}`);
        setTempProjectId(project_id);
    };
    const processEmail = async answer => {
        const email = answer.trim();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            toast('Please enter a valid email address.');
            return;
        }
        setLoading(true);
        let countdownTime = 5;
        setCountdown(countdownTime);

        dispatch(updateProjectWithEmail(tempProjectId, email));

        try {
            let countInterval = setInterval(() => {
                if (countdownTime > 0) {
                    countdownTime--;
                    setCountdown(countdownTime);
                } else if (tempProjectId) {
                    navigate('/demo/' + tempProjectId);
                    clearInterval(countInterval);
                }
            }, 1000);
            trackEvent('emailHeroQuestionAnswered', {
                userEmail,
                projectId: tempProjectId
            });
            dispatch(getProjectByLink(tempProjectId));
        } catch (e) {
            navigate('/auth');
        }
    };

    const processAnswer = async () => {
        if (questionIndex === 0 && answer.length < 1 && !fileKeyS3) {
            toast('Please enter an answer.');
            return;
        }
        setShowInput(false);
        setQuestionIndex(questionIndex + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (questionIndex === 1) {
            processEmail(answer);
        } else if (questionIndex === 0) {
            processFirstAnswer(answer);
        }
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Initial check
        checkIfMobile();

        // Add event listener
        window.addEventListener('resize', checkIfMobile);

        // Cleanup
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const placeholderText =
        questionIndex === 0 ? 'I would like to build a...' : 'steve@apple.com';

    let textAreaHeight = 'h-[200px]';
    if (questionIndex === 2) textAreaHeight = 'h-[80px]';

    return (
        <MainInput
            answer={answer}
            setAnswer={setAnswer}
            fileKeyS3={fileKeyS3}
            setFileKeyS3={setFileKeyS3}
            questionIndex={questionIndex}
            placeholderText={placeholderText}
            textAreaHeight={textAreaHeight}
            processAnswer={processAnswer}
            file={file}
            setFile={setFile}
        />
    );
};
const MainInput = ({
    answer,
    setAnswer,
    fileKeyS3,
    setFileKeyS3,
    questionIndex,
    placeholderText,
    processAnswer,
    textAreaHeight,
    file,
    setFile
}) => {
    const textareaRef = useRef(null);
    const containerRef = useRef(null);

    const handleSubmit = async () => {
        setTimeout(async () => {
            if (containerRef.current) {
                containerRef.current.style.transition = 'opacity 1s';
                containerRef.current.style.opacity = '0';
                await new Promise(resolve => setTimeout(resolve, 500));
                processAnswer();
            }
        }, 200);
    };

    return (
        <div
            ref={containerRef}
            className="flex items-center relative mx-auto max-w-xl dark"
        >
            <PlaceholdersAndVanishInput
                placeholders={
                    questionIndex === 1 ? ['steve@apple.com'] : placeholders
                }
                showAttachFile={questionIndex === 0}
                ref={textareaRef}
                id="mainInput"
                questionIndex={questionIndex}
                type={questionIndex === 1 ? 'email' : 'text'}
                onChange={e => setAnswer(e.target.value)}
                onSubmit={handleSubmit}
                fileKeyS3={fileKeyS3}
                setFileKeyS3={setFileKeyS3}
                file={file}
                setFile={setFile}
            />
        </div>
    );
};
