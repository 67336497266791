import * as seline from '@seline-analytics/web';

const eventToTrack = {
    matchWithDevChat: {
        google: 'conversion_event_purchase',
        reddit: 'SignUp',
        twitter: 'tw-on93x-oqz0y',
        seline: 'match_with_dev_enabled_chat'
    },
    matchWithDevBudgetHeader: {
        google: 'conversion_event_purchase',
        reddit: 'SignUp',
        twitter: 'tw-on93x-oqz0y',
        seline: 'match_with_dev_enabled_budget_header'
    },
    matchWithDevBudget: {
        google: 'conversion_event_purchase',
        reddit: 'SignUp',
        twitter: 'tw-on93x-oqz0y',
        seline: 'match_with_dev_enabled_budget'
    },
    matchWithDevNewProject: {
        google: 'conversion_event_purchase',
        reddit: 'SignUp',
        twitter: 'tw-on93x-oqz0y',
        seline: 'match_with_dev_enabled_new_project'
    },
    meetingScheduledWithPreDev: {
        google: 'conversion_event_book_appointment',
        reddit: 'AddToCart',
        twitter: 'tw-on93x-oqz18',
        seline: 'meeting_scheduled_with_pre_dev'
    },
    meetingScheduledWithAgency: {
        google: 'conversion_event_begin_checkout',
        reddit: 'Purchase',
        twitter: 'tw-on93x-oqz1f',
        seline: 'meeting_scheduled_with_agency'
    },
    landingMatchedWithDev: {
        seline: 'landing_matched_with_dev'
    },
    landingMeetingWithPreDev: {
        seline: 'landing_meeting_with_pre_dev'
    },
    answerForMe: {
        seline: 'answer_for_me'
    },
    changedBudget: {
        seline: 'changed_budget'
    },
    dockShowChat: {
        seline: 'dock_show_chat'
    },
    dockShowSow: {
        seline: 'dock_show_sow'
    },
    dockShowGraph: {
        seline: 'dock_show_graph'
    },
    dockShowRoadmap: {
        seline: 'dock_show_roadmap'
    },
    dockShowPrototype: {
        seline: 'dock_show_prototype'
    },
    dockShowBudget: {
        seline: 'dock_show_budget'
    },
    drawerOpen: {
        seline: 'drawer_open'
    },
    subTasksOpened: {
        seline: 'sub_tasks_opened'
    },
    techStackOpened: {
        seline: 'tech_stack_opened'
    },
    showHours: {
        seline: 'show_hours'
    },
    editMode: {
        seline: 'edit_mode'
    },
    backendGraphShown: {
        seline: 'backend_graph_shown'
    },
    frontendGraphShown: {
        seline: 'frontend_graph_shown'
    },
    userFlowGraphShown: {
        seline: 'user_flow_graph_shown'
    },
    enterpriseSwitchClicked: {
        seline: 'enterprise_switch_clicked'
    },
    soloSwitchClicked: {
        seline: 'solo_switch_clicked'
    },
    loginForFullAccess: {
        seline: 'login_for_full_access'
    },
    firstHeroQuestionAnswered: {
        seline: 'first_question_answered'
    },
    emailHeroQuestionAnswered: {
        seline: 'second_question_answered'
    },
    emailHeroGoogleLogin: {
        seline: 'email_hero_google_login'
    },
    landingFileAttachment: {
        seline: 'landing_file_attachment'
    },
    fileAttachment: {
        seline: 'file_attachment'
    },
    getUnlimitedAccess: {
        seline: 'get_unlimited_access'
    },
    upgradeToRevealYourBudget: {
        seline: 'upgrade_to_reveal_your_budget'
    },
    architectureLoadingHover: {
        seline: 'architecture_loading_hover'
    },
    jumpToFrontOfQueueSolo: {
        seline: 'jump_to_front_of_queue_solo'
    },
    jumpToFrontOfQueueLanding: {
        seline: 'jump_to_front_of_queue_landing'
    },
    findDevelopersClicked: {
        seline: 'find_developers_clicked'
    },
    navUpworkClicked: {
        seline: 'nav_upwork_clicked'
    },
    navClutchClicked: {
        seline: 'nav_clutch_clicked'
    },
    navHowItWorksClicked: {
        seline: 'nav_how_it_works_clicked'
    },
    navPricingClicked: {
        seline: 'nav_pricing_clicked'
    },
    chat_get_started_now: {
        seline: 'get_started_now_chat'
    },
    chat_development_roadmap: {
        seline: 'development_roadmap_chat'
    },
    chat_cost_breakdown: {
        seline: 'cost_breakdown_chat'
    },
    chat_user_stories: {
        seline: 'user_stories_chat'
    },
    enterprise_upgrade_account_clicked: {
        seline: 'enterprise_upgrade_account_clicked'
    },
    solo_upgrade_account_clicked: {
        seline: 'solo_upgrade_account_clicked'
    },
    roadmap_cta_clicked: {
        seline: 'roadmap_cta_clicked'
    },
    docs_cta_clicked: {
        seline: 'docs_cta_clicked'
    },
    drawer_cta_clicked: {
        seline: 'drawer_cta_clicked'
    },
    architecture_cta_clicked: {
        seline: 'architecture_cta_clicked'
    },
    solo_cancel_subscription: {
        seline: 'solo_cancel_subscription'
    },
    enterprise_cancel_subscription: {
        seline: 'enterprise_cancel_subscription'
    }
};

const trackEvent = async (e, options) => {
    try {
        //Google Tracking
        if (eventToTrack[e]?.google) {
            window.gtag('event', eventToTrack[e]?.google, {
                event_callback: async e => {},
                event_timeout: 2000
            });
        }
        //Reddit Tracking
        if (eventToTrack[e]?.reddit) {
            try {
                window.rdt('track', eventToTrack[e]?.reddit);
            } catch (e) {
                console.log('error tracking reddit', e);
            }
        }
        //Twitter Tracking
        if (eventToTrack[e]?.twitter) {
            try {
                window.twq('event', eventToTrack[e]?.twitter, {
                    email_address: options?.userEmail,
                    conversion_id: options?.conversionId
                });
            } catch (e) {
                console.log('error tracking twitter', e);
            }
        }
        //Seline Tracking
        if (eventToTrack[e]?.seline) {
            try {
                seline.track(eventToTrack[e]?.seline, {
                    userId: options?.userId,
                    email: options?.userEmail,
                    conversionId: options?.conversionId,
                    projectId: options?.projectId
                });
            } catch (e) {
                console.log('error tracking seline', e);
            }
        }

        if (!eventToTrack[e]?.seline) {
            seline.track(e, {
                userId: options?.userId,
                email: options?.userEmail,
                conversionId: options?.conversionId,
                projectId: options?.projectId
            });
        }
    } catch (e) {
        console.log('error tracking event', e);
    }
};

export default trackEvent;
