import React from 'react';
import SuperTokens from 'supertokens-auth-react';
import { SuperTokensConfig, EmailPasswordHeadersOverride } from './auth_config';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { Toaster } from 'sonner';
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdpartyemailpassword';
import { ApolloProvider } from '@apollo/client';
import { client } from './api/client';

SuperTokens.init(SuperTokensConfig);

function Auth({ children }) {
    return (
        <SuperTokensWrapper>
            <Toaster closeButton position="top-center" richColors />
            <ThirdpartyEmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignInHeader_Override:
                        EmailPasswordHeadersOverride,
                    EmailPasswordSignUpHeader_Override:
                        EmailPasswordHeadersOverride
                }}
            >
                <ApolloProvider client={client}>{children}</ApolloProvider>
            </ThirdpartyEmailPasswordComponentsOverrideProvider>
        </SuperTokensWrapper>
    );
}

export default Auth;
