import React, { createContext, useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getEnterpriseOrganizationByUser } from '../redux/actions/enterprise/getEnterpriseOrganizationByUser';
import { selectEnterpriseOrganization } from '../redux/reducers/enterprise/enterpriseOrganization';

import { selectApiDomains } from '../redux/reducers/enterprise/clientOnboarding';
import { selectEnterpriseUsers } from '../redux/reducers/enterprise/enterpriseUsers';

import { useParams } from 'react-router-dom';
import { selectAPIKey } from '../redux/reducers/enterprise_widget/enterpriseWidget';
import { getEnterpriseOrganizationByApiKey } from '../redux/actions/enterprise_widget/getEnterpriseOrganizationByApiKey';

const EnterpriseOrganizationContext = createContext();

export function EnterpriseOrganizationProvider({ children }) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const apiKey = useSelector(selectAPIKey);

    const apiDomainsObj = useSelector(selectApiDomains);

    const enterpriseUsersObj = useSelector(selectEnterpriseUsers);
    // api domains
    const apiDomains = apiDomainsObj.data;
    const apiDomainsError = apiDomainsObj.error;
    const apiDomainsLoading = apiDomainsObj.loading;

    const enterpriseUsers = enterpriseUsersObj.data;
    const enterpriseUsersError = enterpriseUsersObj.error;
    const enterpriseUsersLoading = enterpriseUsersObj.loading;

    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();

    useEffect(() => {
        if (apiKey && !enterpriseOrganizationId)
            dispatch(getEnterpriseOrganizationByApiKey(apiKey));
    }, [apiKey, enterpriseOrganizationId]);

    //determine when to mount this data
    useEffect(() => {
        if (enterpriseOrganizationId) {
            dispatch(getEnterpriseOrganizationByUser(enterpriseOrganizationId));
        }
    }, [doesSessionExist, enterpriseOrganizationId]);

    return (
        <EnterpriseOrganizationContext.Provider
            value={{
                enterpriseOrganization,
                enterpriseOrganizationId,
                apiDomains,
                apiDomainsLoading,
                apiDomainsError,
                enterpriseUsers,
                enterpriseUsersError,
                enterpriseUsersLoading
            }}
        >
            {children}
        </EnterpriseOrganizationContext.Provider>
    );
}

export const useEnterpriseOrganization = () =>
    useContext(EnterpriseOrganizationContext);
