import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function Modal({
    id = null,
    open,
    onClose,
    children,
    maxWidth = 'max-w-xl',
    maxHeight = 'max-h-full',
    bgColor = 'bg-white',
    titleColor = 'text-gray-900',
    hideOuter = false,
    title
}) {
    const overlayClasses =
        'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity';
    const containerClasses = 'fixed inset-0 z-10 overflow-y-auto';
    const wrapperClasses =
        'flex min-h-full items-end justify-center py-2 px-2 text-center items-center sm:p-0';
    const panelClasses = hideOuter
        ? `h-full ${bgColor} relative transform overflow-hidden rounded-lg transition-all sm:w-full ${maxWidth} ${maxHeight}`
        : `h-full ${bgColor} relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${maxWidth} ${maxHeight} sm:p-6`;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className={overlayClasses} />
                </Transition.Child>

                <div className={containerClasses}>
                    <div className={wrapperClasses}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={panelClasses}>
                                {!hideOuter && (
                                    <XMarkIcon
                                        onClick={onClose}
                                        className={`z-10 h-6 w-6 absolute cursor-pointer top-4 right-4 ${
                                            bgColor === 'bg-black'
                                                ? 'text-white hover:text-gray-300'
                                                : 'text-gray-800 hover:text-gray-500'
                                        }`}
                                    />
                                )}
                                {title && (
                                    <Dialog.Title
                                        as="h3"
                                        className={`text-lg leading-6 font-medium ${titleColor}`}
                                    >
                                        {title}
                                    </Dialog.Title>
                                )}
                                <div id={id}>{children}</div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
