import gql from 'graphql-tag';
import { message, project } from '../helper';

export const CREATE_ENTERPRISE_PROJECT = gql`
	mutation createEnterpriseProject(
		$name: String!
		$type: String!
		$userEmail: String
		$apiKey: String!
		$hasExistingContext: Boolean
		$fileKeyS3: String
		$isWidget: Boolean
		$budget: Int
		$urgency: Int
		$phoneNumber: String
		$businessURL: String
		$ad_params: String
		$referralLink: String
	) {
		createEnterpriseProject(
			name: $name
			type: $type
			userEmail: $userEmail
			hasExistingContext: $hasExistingContext
			apiKey: $apiKey
			fileKeyS3: $fileKeyS3
			isWidget: $isWidget
			budget: $budget
			urgency: $urgency
			phoneNumber: $phoneNumber
			businessURL: $businessURL
			ad_params: $ad_params
			referralLink: $referralLink
		) {
			${project}
		}
	}
`;

export const INITIATE_ENTERPRISE_FOLLOW_UP_QUESTION = gql`
	mutation initiateEnterpriseFollowUpQuestionStream(
		$apiKey: String
		$answer: MessageInput!
		$projectId: ID
		$isWidget: Boolean
	) {
		initiateEnterpriseFollowUpQuestionStream(
			answer: $answer
			projectId: $projectId
			apiKey: $apiKey
			isWidget: $isWidget
		) {
			${message}
		}
	}
`;

export const SUBMIT_HERO_EXPERIENCE = gql`
    mutation submitHeroExperience(
        $answers: [MessageInput]!
        $userEmail: String
        $matchWithDev: Boolean
        $budget: Int
        $urgency: Int
        $ad_params: String
        $referralLink: String
    ) {
        submitHeroExperience(
            answers: $answers
            userEmail: $userEmail
            matchWithDev: $matchWithDev
            budget: $budget
            urgency: $urgency
            ad_params: $ad_params
            referralLink: $referralLink
        )
    }
`;

export const REQUEST_ENTERPRISE_MEETING = gql`
    mutation requestEnterpriseMeeting($apiKey: String!, $projectId: String!) {
        requestEnterpriseMeeting(apiKey: $apiKey, projectId: $projectId) {
            _id
            name
            created
            meetingRequested
        }
    }
`;

export const INITIATE_HERO_RANDOM_EXPERIENCE_STREAM = gql`
    mutation initiateHeroRandomExperienceStream(
        $randId: String!
        $inspiration: String
        $answers: [String!]!
    ) {
        initiateHeroRandomExperienceStream(
            randId: $randId
            answers: $answers
            inspiration: $inspiration
        ) {
            suggestion
            completed
            randId
        }
    }
`;

export const SEND_PHONE_NUMBER_VERIFICATION_CODE = gql`
    mutation sendPhoneNumberVerificationCode($phoneNumber: String!) {
        sendPhoneNumberVerificationCode(phoneNumber: $phoneNumber)
    }
`;

export const VERIFY_PHONE_NUMBER = gql`
    mutation verifyPhoneNumber($phoneNumber: String!, $code: String!) {
        verifyPhoneNumber(phoneNumber: $phoneNumber, code: $code)
    }
`;
