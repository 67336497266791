import React, { lazy, Suspense, useEffect } from 'react';
import {
    Route,
    BrowserRouter as Router,
    Routes,
    useLocation
} from 'react-router-dom';
import { ProjectProvider } from './context-providers/Project';
import { ProjectsProvider } from './context-providers/Projects';
import { LoadingProvider } from './context-providers/Loading';
import { SubscriptionPaymentProvider } from './context-providers/SubscriptionPayment';
import { GraphProvider } from './context-providers/Graph';
import { EnterpriseOrganizationsProvider } from './context-providers/EnterpriseOrganizations';
import { EnterpriseOrganizationProvider } from './context-providers/EnterpriseOrganization';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { useEnterpriseOrganization } from './context-providers/EnterpriseOrganization';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { PreBuiltUIList } from './auth_config';
import { Outlet } from 'react-router-dom';
import { AnalyticsWrapper } from './Analytics';
import Auth from './Auth';
import { Loading } from './components/Components/Loading';
import LandingPage from './components/Landing';
import MatchLanding from './components/Landing/matchLanding';
import SiteHeader from './components/Landing/site-header';
import PricingSection from './components/Pricing/pricing-section';

// Scroll to top component
function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

// const LandingPage = lazy(() => import('./components/Landing'));
// const MatchLanding = lazy(() => import('./components/Landing/matchLanding'));

// Lazy load components
const Footer = lazy(() => import('./components/Footer'));
const MainHeader = lazy(
    () => import('./components/WidgetPages/ChatWidgetPage/Widget/MainHeader')
);
const UserAuthForm = lazy(() => import('./components/Auth/user-auth-form'));
const ProjectDashboard = lazy(() => import('./components/ProjectDashboard'));
const ChatWidgetPage = lazy(
    () => import('./components/WidgetPages/ChatWidgetPage')
);
const Home = lazy(() => import('./components/Home'));
const EnterpriseLanding = lazy(() => import('./components/EnterpriseLanding'));
const Forgot = lazy(() => import('./components/Auth/forgot'));
const Reset = lazy(() => import('./components/Auth/reset'));
const NewProject = lazy(() => import('./components/Home/NewProject'));
const EnterpriseHome = lazy(() => import('./components/EnterpriseHome'));
const NewOrganization = lazy(
    () => import('./components/EnterpriseHome/NewOrganization')
);
const ClientProjects = lazy(
    () => import('./components/EnterpriseHome/ClientProjects')
);
const WidgetManagement = lazy(
    () => import('./components/EnterpriseHome/WidgetManagement')
);
const EnterpriseUsers = lazy(
    () => import('./components/EnterpriseHome/EnterpriseUsers')
);
const Widget = lazy(
    () => import('./components/WidgetPages/ChatWidgetPage/Widget')
);
const CreateNewProject = lazy(() => import('./components/CreateNewProject'));
const KnowledgeManagement = lazy(
    () => import('./components/EnterpriseHome/KnowledgeManagement')
);
const LeadGeneration = lazy(
    () => import('./components/EnterpriseHome/LeadGeneration')
);
const VerifyLinkedIn = lazy(() => import('./components/Auth/VerifyLinkedIn'));
const AgencySchedulingPage = lazy(
    () => import('./components/AgencySchedulingPage')
);
const DevAgencyLanding = lazy(() => import('./components/DevAgencyLanding'));
const PlatformComparison = lazy(() => import('./components/Comparison'));
const Journey = lazy(() => import('./components/Landing/Journey'));
const FeaturesSection = lazy(
    () => import('./components/Landing/FeaturesSection')
);
const ReferralPartners = lazy(
    () => import('./components/EnterpriseHome/ReferralPartners')
);

const EnterpriseWrapper = () => {
    const { enterpriseOrganization } = useEnterpriseOrganization();
    return !enterpriseOrganization ? (
        <Loading text="Organization" />
    ) : (
        <Outlet />
    );
};

function App() {
    return (
        <Auth>
            <Router>
                <AnalyticsWrapper>
                    <Routes>
                        {/* This shows the login UI on "/auth" route */}
                        {getSuperTokensRoutesForReactRouterDom(
                            require('react-router-dom'),
                            PreBuiltUIList
                        )}
                        <Route
                            path="/auth/*"
                            element={
                                <div className="dark bg-black w-screen h-screen items-center justify-center flex">
                                    <Suspense fallback={<Loading />}>
                                        <UserAuthForm />
                                    </Suspense>
                                </div>
                            }
                        />
                        <Route
                            path="/auth/reset-password"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Reset />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/auth/forgot-password"
                            element={
                                <Suspense fallback={<Loading />}>
                                    <Forgot />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider
                                            isHomePage={true}
                                        >
                                            <ProjectProvider>
                                                <GraphProvider>
                                                    <LoadingProvider>
                                                        <SiteHeader />
                                                        <LandingPage />
                                                        <Suspense
                                                            fallback={null}
                                                        >
                                                            <Footer />
                                                        </Suspense>
                                                    </LoadingProvider>
                                                </GraphProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                </EnterpriseOrganizationsProvider>
                            }
                        >
                            <Route path="demo/:projectId" />
                        </Route>
                        <Route
                            path="/dev-agency"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <SiteHeader />
                                    <Suspense fallback={<Loading />}>
                                        <DevAgencyLanding />
                                    </Suspense>
                                    <Suspense fallback={null}>
                                        <Footer />
                                    </Suspense>
                                </EnterpriseOrganizationsProvider>
                            }
                        />
                        <Route
                            path="/competitors"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <ScrollToTop />
                                    <SiteHeader />
                                    <div className="bg-black mt-[57px] ">
                                        <div className="pt-12">
                                            <Suspense fallback={<Loading />}>
                                                <PlatformComparison />
                                            </Suspense>
                                        </div>
                                    </div>
                                    <Suspense fallback={null}>
                                        <Footer />
                                    </Suspense>
                                </EnterpriseOrganizationsProvider>
                            }
                        />
                        <Route
                            path="/how-it-works"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <div className="bg-black">
                                        <ScrollToTop />

                                        <SiteHeader />
                                        <div className="dark max-w-screen-xl mx-auto bg-black mt-[57px] pt-12">
                                            <Suspense fallback={null}>
                                                <Journey />
                                            </Suspense>
                                            <Suspense fallback={null}>
                                                <FeaturesSection />
                                            </Suspense>
                                        </div>
                                        <Suspense fallback={<Loading />}>
                                            <Footer />
                                        </Suspense>
                                    </div>
                                </EnterpriseOrganizationsProvider>
                            }
                        />
                        <Route
                            path="/pricing"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <div className="bg-black">
                                        <ScrollToTop />

                                        <SiteHeader />
                                        <div className="dark max-w-screen-xl mx-auto bg-black">
                                            <Suspense fallback={null}>
                                                <div className="pt-12">
                                                    <PricingSection />
                                                </div>
                                            </Suspense>
                                        </div>
                                        <Suspense fallback={<Loading />}>
                                            <Footer />
                                        </Suspense>
                                    </div>
                                </EnterpriseOrganizationsProvider>
                            }
                        />
                        <Route
                            path="/match-me"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider
                                            isHomePage={true}
                                        >
                                            <ProjectProvider>
                                                <GraphProvider>
                                                    <LoadingProvider>
                                                        <SiteHeader />
                                                        <MatchLanding />
                                                        <Suspense
                                                            fallback={
                                                                <Loading />
                                                            }
                                                        >
                                                            <Footer />
                                                        </Suspense>
                                                    </LoadingProvider>
                                                </GraphProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                </EnterpriseOrganizationsProvider>
                            }
                        ></Route>
                        <Route
                            path="/build-my-idea"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider
                                            isHomePage={true}
                                        >
                                            <ProjectProvider>
                                                <GraphProvider>
                                                    <LoadingProvider>
                                                        <SiteHeader />
                                                        <MatchLanding />
                                                        <Suspense
                                                            fallback={
                                                                <Loading />
                                                            }
                                                        >
                                                            <Footer />
                                                        </Suspense>
                                                    </LoadingProvider>
                                                </GraphProvider>
                                            </ProjectProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                </EnterpriseOrganizationsProvider>
                            }
                        ></Route>

                        <Route
                            path="/dev-agency-scheduling-link"
                            element={
                                <EnterpriseOrganizationsProvider>
                                    <Suspense fallback={<Loading />}>
                                        <AgencySchedulingPage />
                                    </Suspense>
                                    <Suspense fallback={null}>
                                        <Footer />
                                    </Suspense>
                                </EnterpriseOrganizationsProvider>
                            }
                        />
                        <Route
                            path="/dev-agency-scheduling-link/:creditId"
                            element={
                                <SessionAuth>
                                    <EnterpriseOrganizationsProvider>
                                        <Suspense fallback={<Loading />}>
                                            <AgencySchedulingPage />
                                        </Suspense>
                                        <Suspense fallback={null}>
                                            <Footer />
                                        </Suspense>
                                    </EnterpriseOrganizationsProvider>
                                </SessionAuth>
                            }
                        />
                        <Route
                            path="/enterprise"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider>
                                        <ProjectProvider isChatWidget={true}>
                                            <LoadingProvider>
                                                <Suspense fallback={null}>
                                                    <EnterpriseLanding />
                                                </Suspense>
                                            </LoadingProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/iframe/enterprise/chat/:apiKey"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider
                                        isChatWidget={true}
                                    >
                                        <ProjectProvider isChatWidget={true}>
                                            <LoadingProvider>
                                                <Suspense fallback={null}>
                                                    <Widget />
                                                </Suspense>
                                            </LoadingProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/enterprise/chat/:apiKey"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider
                                        isChatWidget={true}
                                    >
                                        <ProjectProvider isChatWidget={true}>
                                            <LoadingProvider>
                                                <Suspense fallback={null}>
                                                    <ChatWidgetPage />
                                                </Suspense>
                                            </LoadingProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/share/enterprise/:enterpriseOrganizationId/:projectId"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider isShare={true}>
                                        <ProjectProvider>
                                            <GraphProvider>
                                                <LoadingProvider>
                                                    <div className="flex flex-col h-[100vh] w-full">
                                                        <Suspense
                                                            fallback={
                                                                <Loading />
                                                            }
                                                        >
                                                            <ProjectDashboard />
                                                        </Suspense>
                                                    </div>
                                                </LoadingProvider>
                                            </GraphProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/share/:apiKey/:projectId"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider isShare={true}>
                                        <ProjectProvider>
                                            <GraphProvider>
                                                <LoadingProvider>
                                                    <div className="flex flex-col h-[100vh] w-full">
                                                        <Suspense
                                                            fallback={
                                                                <Loading />
                                                            }
                                                        >
                                                            <ProjectDashboard />
                                                        </Suspense>
                                                    </div>
                                                    <Suspense
                                                        fallback={<Loading />}
                                                    >
                                                        <Footer />
                                                    </Suspense>
                                                </LoadingProvider>
                                            </GraphProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/share/:projectId"
                            element={
                                <EnterpriseOrganizationProvider>
                                    <SubscriptionPaymentProvider isShare={true}>
                                        <ProjectProvider>
                                            <GraphProvider>
                                                <LoadingProvider>
                                                    <Suspense
                                                        fallback={<Loading />}
                                                    >
                                                        <ProjectDashboard />
                                                    </Suspense>
                                                    <Suspense fallback={null}>
                                                        <Footer />
                                                    </Suspense>
                                                </LoadingProvider>
                                            </GraphProvider>
                                        </ProjectProvider>
                                    </SubscriptionPaymentProvider>
                                </EnterpriseOrganizationProvider>
                            }
                        />
                        <Route
                            path="/enterprise/dashboard"
                            element={
                                <SessionAuth>
                                    <EnterpriseOrganizationsProvider>
                                        <EnterpriseOrganizationProvider>
                                            <SubscriptionPaymentProvider
                                                isEnterprise={true}
                                            >
                                                <ProjectsProvider>
                                                    <ProjectProvider>
                                                        <GraphProvider>
                                                            <LoadingProvider>
                                                                <Suspense
                                                                    fallback={
                                                                        <Loading />
                                                                    }
                                                                >
                                                                    <EnterpriseHome />
                                                                </Suspense>
                                                            </LoadingProvider>
                                                        </GraphProvider>
                                                    </ProjectProvider>
                                                </ProjectsProvider>
                                            </SubscriptionPaymentProvider>
                                        </EnterpriseOrganizationProvider>
                                    </EnterpriseOrganizationsProvider>
                                </SessionAuth>
                            }
                        >
                            <Route
                                index
                                path="new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <NewOrganization />
                                    </Suspense>
                                }
                            />

                            <Route
                                path=":enterpriseOrganizationId"
                                element={<EnterpriseWrapper />}
                            >
                                <Route
                                    path="new-project/:apiKey"
                                    element={
                                        <div
                                            className={`h-full flex flex-col justify-between items-center w-full`}
                                        >
                                            <div className="flex justify-center items-center h-full w-full">
                                                <section
                                                    className={`h-[590px] md:border-gray-200 md:border rounded-lg md:shadow-lg object-cover mx-auto max-w-[440px] bg-white flex-grow flex flex-col justify-between`}
                                                >
                                                    <Suspense
                                                        fallback={<Loading />}
                                                    >
                                                        <CreateNewProject
                                                            isEnterprise={true}
                                                        />
                                                    </Suspense>
                                                    <Suspense fallback={null}>
                                                        <MainHeader />
                                                    </Suspense>
                                                </section>
                                            </div>
                                        </div>
                                    }
                                ></Route>

                                <Route
                                    path="referralPartners"
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            <ReferralPartners />
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="widgetManagement"
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            <WidgetManagement />
                                        </Suspense>
                                    }
                                />

                                <Route
                                    path="knowledgeManagement"
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            <KnowledgeManagement />
                                        </Suspense>
                                    }
                                />

                                <Route path="leadGeneration">
                                    <Route
                                        index
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <LeadGeneration />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path=":projectId"
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <ProjectDashboard
                                                    isLeadGen={true}
                                                />
                                            </Suspense>
                                        }
                                    />
                                </Route>

                                <Route path="clientProjects">
                                    <Route
                                        index
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <ClientProjects />
                                            </Suspense>
                                        }
                                    />

                                    <Route
                                        path=":projectId"
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <ProjectDashboard />
                                            </Suspense>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="enterpriseUsers"
                                    element={
                                        <Suspense fallback={<Loading />}>
                                            <EnterpriseUsers />
                                        </Suspense>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path="/projects"
                            element={
                                <SessionAuth>
                                    <EnterpriseOrganizationProvider>
                                        <SubscriptionPaymentProvider
                                            isSolo={true}
                                        >
                                            <ProjectsProvider>
                                                <ProjectProvider>
                                                    <GraphProvider>
                                                        <LoadingProvider>
                                                            <Suspense
                                                                fallback={
                                                                    <Loading />
                                                                }
                                                            >
                                                                <Home />
                                                            </Suspense>
                                                        </LoadingProvider>
                                                    </GraphProvider>
                                                </ProjectProvider>
                                            </ProjectsProvider>
                                        </SubscriptionPaymentProvider>
                                    </EnterpriseOrganizationProvider>
                                </SessionAuth>
                            }
                        >
                            <Route
                                index
                                path="new"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <NewProject />
                                    </Suspense>
                                }
                            />

                            <Route
                                path=":projectId"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <ProjectDashboard />
                                    </Suspense>
                                }
                            />
                        </Route>
                        <Route
                            path="verifyLinkedIn"
                            element={
                                <SessionAuth>
                                    <Suspense fallback={<Loading />}>
                                        <VerifyLinkedIn />
                                    </Suspense>
                                </SessionAuth>
                            }
                        />
                    </Routes>
                </AnalyticsWrapper>
            </Router>
        </Auth>
    );
}

export default App;
