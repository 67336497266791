import { HeroExperience } from './HeroExperience';
import RadialGradient from '../Components/magicui/RadialGradient';
import { ParticlesEffect } from './HeroExperience/ParticlesEffect';

export const MatchLanding = () => {
    return (
        <div className="bg-black">
            <div className="max-w-screen-xl mx-auto">
                <RadialGradient />
            </div>

            <HeroExperience />

            <div className="fixed">
                <ParticlesEffect />
            </div>
        </div>
    );
};

export default MatchLanding;
