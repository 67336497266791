import { client } from '../../../api/client';
import { GET_SOLO_SUBSCRIPTION_LINKS } from '../../../api/graphQL/solo/query';
import { setSubscriptionLinks } from '../../reducers/generic/profile';

export const getSoloSubscriptionLinks =
    userEmail => async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: GET_SOLO_SUBSCRIPTION_LINKS,
                variables: { userEmail }
            });

            let links = data.getSoloSubscriptionLinks;

            const filteredLinks = links
                .filter(link => link.trial === false)
                .map(link => {
                    link.url = link.url + '?prefilled_email=' + userEmail;
                    return link;
                });
            dispatch(setSubscriptionLinks(filteredLinks));

            return filteredLinks;
        } catch (error) {
            console.log({ error });
            console.log(JSON.stringify(error, null, 2));
        }
    };
