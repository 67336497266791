import { useCallback, lazy, Suspense, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Particles = lazy(() => import('react-tsparticles'));

export const ParticlesEffect = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    const particlesInit = useCallback(async engine => {
        const { loadFull } = await import('tsparticles');
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        setIsLoaded(true);
    }, []);

    return (
        <Suspense fallback={<div className="w-full h-full" />}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isLoaded ? 1 : 0 }}
                    transition={{ duration: 1.5, ease: 'easeInOut' }}
                >
                    <Particles
                        id="tsparticles"
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={particleOptions}
                    />
                </motion.div>
            </AnimatePresence>
        </Suspense>
    );
};

const particleOptions = {
    particles: {
        number: {
            value: 20,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: ['#ffffff', '#ff9900', '#00ff00']
        },
        shape: {
            type: 'circle'
        },
        opacity: {
            value: 0.5,
            random: false,
            animation: {
                enable: true,
                speed: 1,
                minimumValue: 0.1,
                sync: false
            }
        },
        size: {
            value: 8,
            random: true,
            animation: {
                enable: false,
                speed: 20,
                minimumValue: 0.1,
                sync: false
            }
        },
        lineLinked: {
            enable: true,
            distance: 200,
            color: '#ffffff',
            opacity: 0.4,
            width: 1
        },
        move: {
            enable: true,
            speed: 0.5,
            direction: 'none',
            random: false,
            straight: false,
            outModes: {
                default: 'out'
            },
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    detectRetina: true
};

export default ParticlesEffect;
