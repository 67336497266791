import { lazy, Suspense } from 'react';
import { ChatInput } from '../../Chat/ChatInput';
import { ExistingProjectContext } from '../../Chat/ExistingProjectContext';
import { Progress } from '../../Chat/Progress';

const ChatHistory = lazy(() => import('../../Chat/ChatHistory'));

const ChatWidget = () => {
    return (
        <>
            <div className="h-[65px] flex flex-col justify-end border-b border-gray-200">
                <Progress />
            </div>
            <ExistingProjectContext />
            <Suspense fallback={null}>
                <ChatHistory />
            </Suspense>
            <ChatInput isWidget={true} />
        </>
    );
};
export default ChatWidget;
