import { Button } from '../Components/magicui/ui/button';
import { cn } from '../Components/magicui/lib/utils';
import { CheckIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectAddOn,
    selectEnterpriseOrganization,
    setAddOn
} from '../../redux/reducers/enterprise/enterpriseOrganization';
import { createEnterpriseStripeLinkWithQuantityAndDuration } from '../../redux/actions/enterprise/createEnterpriseStripeLinkWithQuantityAndDuration';
import { toast } from 'sonner';
import { salesCalendlyLink } from '../../constants';

type Interval = 'monthly' | 'yearly';

const toHumanPrice = (price: number): string => {
    const convertedPrice = price / 100;
    return Number.isInteger(convertedPrice)
        ? convertedPrice?.toString()
        : convertedPrice?.toFixed(0);
};

interface PriceCardProps {
    price: {
        id: string;
        name: string;
        description: string;
        features: string[];
        addons: string[];
        stripeAddOns?: any;
        monthlyPrice: number;
        yearlyPrice: number;
        textColor: string;
        perUser?: boolean;
    };
    interval: Interval;
    isInView: boolean;
    idx: number;
    freeTrialActivated: boolean;
    subscriptionLinks: any;
    type: string;
}

export default function PriceCard({
    price,
    interval,
    isInView,
    idx,
    freeTrialActivated,
    subscriptionLinks,
    type
}: PriceCardProps) {
    const [seats, setSeats] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const addOn = useSelector(selectAddOn);

    let enterpriseOrganizationId = enterpriseOrganization?._id?.toString();

    const getLink = (priceId: string): string => {
        if (priceId === 'beta') {
            return 'https://buy.stripe.com/7sI17Jc4pe1gddSaDa';
        } else {
            const duration = interval === 'yearly' ? 'Yearly' : 'Monthly';
            if (subscriptionLinks) {
                if (priceId === 'enterprise' && type !== 'enterprise') {
                    return '/enterprise/dashboard';
                } else {
                    let sub = subscriptionLinks.find(
                        (link: any) =>
                            link.tier === priceId &&
                            link.duration.toLowerCase() ===
                                duration.toLowerCase() &&
                            link.trial === freeTrialActivated
                    );
                    const link = sub?.url;
                    return link;
                }
            } else {
                return '/auth';
            }
        }
    };

    const handleSelectPlan = async () => {
        setIsLoading(true);
        try {
            if (seats <= 0) {
                toast.error('Number of seats must be greater than 0');
            }
            let stripeLink =
                await createEnterpriseStripeLinkWithQuantityAndDuration({
                    enterpriseOrganizationId,
                    duration: interval,
                    quantity: seats,
                    addOns: addOn ? [addOn] : []
                });
            if (addOn)
                stripeLink = stripeLink + '?prefilled_promo_code=LEADGENBETA';
            if (stripeLink) {
                window.open(stripeLink, '_blank', 'noopener,noreferrer');
            } else {
                throw new Error('Failed to generate Stripe link');
            }
        } catch (error) {
            console.error('Error creating Stripe link:', error);
            // Handle error (e.g., show error message to user)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <motion.div
            key={price.id}
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{
                duration: 1,
                delay: 0.1 + idx * 0.05,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            className={cn(
                'mx-auto relative flex max-w-2xl flex-col gap-4 rounded-2xl border p-4 text-black dark:text-white overflow-hidden',
                'hover:border-2 hover:border-[var(--color-one)] dark:hover:border-[var(--color-one)]'
            )}
        >
            <TitleAndDescription price={price} />
            {freeTrialActivated && (
                <p className="-mb-4 text-xs">
                    <strong>7 days free</strong>, then
                </p>
            )}
            <Price price={price} interval={interval} />
            {price.id === 'EnterprisePremium' && subscriptionLinks ? (
                <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2">
                        <label htmlFor="seats" className="text-sm">
                            Number of seats:
                        </label>
                        <input
                            type="number"
                            id="seats"
                            min="1"
                            value={seats}
                            onChange={e => setSeats(parseInt(e.target.value))}
                            className="w-16 px-2 py-1 border rounded text-black"
                        />
                    </div>
                    <Button
                        onClick={handleSelectPlan}
                        disabled={isLoading}
                        className={cn(
                            'group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter',
                            'transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2'
                        )}
                    >
                        <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                        {isLoading
                            ? 'Loading...'
                            : freeTrialActivated
                              ? 'Start Free Trial'
                              : 'Select Plan'}
                    </Button>
                </div>
            ) : (
                <Link to={getLink(price.id)}>
                    <Button
                        className={cn(
                            'group relative w-full text-black gap-2 overflow-hidden text-lg font-semibold tracking-tighter',
                            'transform-gpu ring-offset-current transition-all duration-300 ease-out hover:ring-2 hover:ring-primary hover:ring-offset-2'
                        )}
                    >
                        <span className="absolute right-0 h-32 w-8 translate-x-12 rotate-12 transform-gpu bg-white opacity-10 transition-all duration-1000 ease-out group-hover:-translate-x-96 dark:bg-black" />
                        {type === 'enterprise' || type === 'solo' ? (
                            <p>
                                {freeTrialActivated
                                    ? 'Start Free Trial'
                                    : 'Select Plan'}
                            </p>
                        ) : (
                            <p>Get Started</p>
                        )}
                    </Button>
                </Link>
            )}
            <hr className="m-0 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />

            {price?.stripeAddOns && price?.stripeAddOns?.length > 0 && type ? (
                <StripeAddOns price={price} />
            ) : (
                <Features price={price} />
            )}
            <ScheduleMeeting price={price} />
        </motion.div>
    );
}

export const StripeAddOns = ({ price }) => {
    const dispatch = useDispatch();
    const addOn = useSelector(selectAddOn);

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-semibold mb-2">Special Add-ons:</h3>
            <div className="grid grid-cols-2 gap-4">
                {price.stripeAddOns.map((addon: any) => (
                    <motion.div
                        key={addon.id}
                        className={`relative p-4 rounded-lg border-2 cursor-pointer transition-all duration-300 ${
                            addOn === addon.id
                                ? 'border-gray-500 bg-gray-100'
                                : 'border-gray-200 hover:border-blue-300'
                        }`}
                        onClick={() => {
                            if (addOn === addon.id) {
                                dispatch(setAddOn(null));
                            } else {
                                dispatch(setAddOn(addon.id));
                            }
                        }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <div className="flex items-center justify-between mb-2">
                            <div className="flex flex-col sm:flex-row gap-x-2 gap-y-2">
                                {addon.icon(
                                    addOn === addon.id
                                        ? 'text-black'
                                        : 'text-gray-400'
                                )}
                                <h4
                                    className={`text-sm font-semibold ${
                                        addOn === addon.id
                                            ? 'text-black'
                                            : 'text-gray-400'
                                    }`}
                                >
                                    {addon.title}
                                </h4>
                            </div>
                        </div>
                        <p
                            className={`text-xs mb-2 ${
                                addOn === addon.id
                                    ? 'text-gray-800'
                                    : 'text-gray-600 dark:text-gray-300'
                            }`}
                        >
                            {addon.description}
                        </p>
                        <div
                            className={`absolute top-2 right-2 w-4 h-4 rounded-full border-2 ${
                                addOn === addon.id
                                    ? 'border-gray-500 bg-gray-500'
                                    : 'border-gray-300'
                            }`}
                        >
                            {addOn === addon.id && (
                                <CheckIcon className="w-3 h-3 text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                            )}
                        </div>

                        <div className="flex flex-col w-full">
                            {addon.halfOff && (
                                <div className="flex justify-center items-center gap-2">
                                    <span className="text-xs font-bold text-red-800 bg-gradient-to-r from-red-100 to-pink-100 px-3 py-1 rounded-full shadow-sm border border-red-200 animate-pulse transform hover:scale-105 transition-transform">
                                        🎉 50% off first month! 🎉
                                    </span>
                                </div>
                            )}
                            <span
                                className={`text-sm font-bold mt-2 ${
                                    addOn === addon.id
                                        ? 'text-blue-600'
                                        : 'text-blue-200'
                                }`}
                            >
                                $
                                {addon.halfOff
                                    ? Math.floor(addon.price / 2)
                                    : addon.price}{' '}
                                / month
                            </span>
                            {addon.halfOff && (
                                <span className="text-x s text-gray-500">
                                    ${addon.price} / month after first month
                                </span>
                            )}
                        </div>
                        {addon.extraDescription && (
                            <span className="text-2xs text-gray-500">
                                {addon.extraDescription}
                            </span>
                        )}
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

//
export const Price = ({ price, interval }) => {
    return (
        <motion.div
            key={`${price.id}-${interval}`}
            initial="initial"
            animate="animate"
            variants={{
                initial: { opacity: 0, y: 12 },
                animate: { opacity: 1, y: 0 }
            }}
            transition={{
                duration: 0.4,
                delay: 0.1,
                ease: [0.21, 0.47, 0.32, 0.98]
            }}
            className="flex flex-col gap-1"
        >
            <span className="text-4xl font-bold text-black dark:text-white">
                $
                {interval === 'yearly'
                    ? toHumanPrice(price.yearlyPrice / 12)
                    : toHumanPrice(price.monthlyPrice)}
                <span className="text-xs">
                    {' '}
                    / month{price.perUser ? ' per seat' : ''}
                </span>
            </span>
            {interval === 'yearly' && (
                <span className="text-xs block mt-1 text-gray-400">
                    Billed annually
                </span>
            )}
        </motion.div>
    );
};

export const TitleAndDescription = ({ price }) => {
    return (
        <div className="flex items-center">
            <div className="">
                <h2
                    className={`text-base font-semibold leading-7 ${price.textColor}`}
                >
                    {price.name}
                </h2>
                <p className="text-sm leading-5 text-black/70 dark:text-white">
                    {price.description}
                </p>
            </div>
        </div>
    );
};

export const Features = ({ price }) => {
    return (
        <>
            {price.features && price.features.length > 0 && (
                <ul className="flex flex-col gap-1 font-normal">
                    {price.features.map((feature: string, idx: number) => (
                        <li
                            key={idx}
                            className="flex items-center gap-2 text-xs font-medium text-black dark:text-white leading-relaxed"
                        >
                            <CheckIcon className="h-4 w-4 shrink-0 rounded-full bg-green-400 p-[2px] text-black dark:text-white" />
                            <span className="flex">{feature}</span>
                        </li>
                    ))}
                </ul>
            )}
            {price.addons && price.addons.length > 0 && (
                <div className="">
                    <h3 className="text-sm font-semibold mb-2">
                        Special Add-ons:
                    </h3>
                    <ul className="flex flex-col gap-1 font-normal">
                        {price.addons.map((addon: string, idx: number) => (
                            <li
                                key={idx}
                                className="flex items-center gap-2 text-xs font-medium text-black dark:text-white leading-relaxed"
                            >
                                <CheckIcon className="h-4 w-4 shrink-0 rounded-full bg-blue-400 p-[2px] text-black dark:text-white" />
                                <span className="flex">{addon}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export const ScheduleMeeting = ({ price }) => {
    if (price.id !== 'EnterprisePremium') return null;
    return (
        <div className="text-center border-t border-gray-200 dark:border-gray-700 py-2 px-6 text-sm">
            <p className="text-md font-semibold text-gray-800 dark:text-gray-200">
                Need more than 10 seats or want to use the special add-ons?{' '}
                <br />
                <a
                    href={salesCalendlyLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600 underline hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600 transition-colors duration-300"
                >
                    Contact us
                </a>
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
                Schedule a meeting to discuss custom plans and pricing.
            </p>
        </div>
    );
};
