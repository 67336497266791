import { MegaphoneIcon, WindowIcon } from '@heroicons/react/24/outline';

export let basicPlan = {
    id: 'basic',
    name: 'Basic',
    description:
        'A basic free plan for individuals or teams to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Basic Product Documentation',
        'Basic Product Roadmap'
    ],
    missingFeatures: [
        'Unlimited Projects',
        'Jira/Linear Integration',
        'Instant Prototype'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

export let basicPlanEnterprise = {
    id: 'basic',
    name: 'Basic',
    description:
        'A basic free plan for individuals or teams to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Basic Product Documentation',
        'Basic Product Roadmap'
    ],
    missingFeatures: [
        'Unlimited Projects',
        'Jira/Linear Integration',
        'Instant Prototype',
        'Lead Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

let soloPlusPlan = {
    id: 'SoloPlus',
    name: 'Solo Plus',
    description: 'An intro plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Priority Match with Dev Agencies'
    ],
    monthlyPrice: 1400,
    yearlyPrice: 14000,
    textColor: 'text-blue-400'
};

export let soloPremiumPlan = {
    id: 'SoloPremium',
    name: 'Solo Premium',
    description: 'The ultimate plan for individuals',
    features: [
        'Everything in Solo Plus +',
        'Jira/Linear Integration',
        'Instant Prototypes'
    ],
    monthlyPrice: 4900,
    yearlyPrice: 49900,
    textColor: 'text-blue-500'
};

let enterprisePremiumPlan = {
    id: 'EnterprisePremium',
    name: 'Enterprise Premium',
    description: 'An enterprise plan made for product teams',
    features: [
        'Everything in Solo Premium +',
        'Advanced Collaboration Features',
        'Configurable Knowledge Base',
        'Data Security and Compliance'
    ],
    addons: [
        'Project Scoping Widget',
        'Lead Generation',
        'Auto Assign Team Members to Tasks'
    ],
    stripeAddOns: [
        {
            id: 'WidgetManagement',
            price: 249,
            title: 'Onboarding Widget',
            description:
                'Seamlessly integrate pre.dev scoping widget to streamline client onboarding directly on your website',
            icon: textColor => (
                <WindowIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        },
        {
            id: 'LeadGenPro',
            price: 999,
            title: 'Lead Gen + Onboarding Widget',
            description:
                '100 credits / month to view and connect with qualified leads, including formal introductions by pre.dev.',
            icon: textColor => (
                <MegaphoneIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            ),
            extraDescription:
                'pre.dev takes a 10% comission on all successful contracts',
            halfOff: true
        }
        // {
        //     id: 'AutoAssignTeamMembers',
        //     price: 499,
        //     title: 'Auto Assign Team Members',
        //     description:
        //         'Auto assign team members to tasks based on their skills and availability'
        // }
    ],
    monthlyPrice: 9900,
    yearlyPrice: 99900,
    textColor: 'text-green-500',
    perUser: true
};

export const prices = ({ disabledBasic, disabledSolo, disableEnterprrise }) => {
    let plans = [];

    if (!disabledBasic) {
        plans.push(basicPlan);
    }

    if (!disabledSolo) {
        plans.push(soloPlusPlan, soloPremiumPlan);
    }

    if (!disableEnterprrise) {
        plans.push(enterprisePremiumPlan);
    }

    return plans;
};
