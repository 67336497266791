import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendPhoneNumberVerificationCode } from '../../redux/actions/enterprise_widget/send_phone_number_verification_code';
import { verifyPhoneNumber } from '../../redux/actions/enterprise_widget/verify_phone_number';
import { PhoneInput } from '../Components/PhoneInput';
import { toast } from 'sonner';

export function isValidPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
        return false;
    }

    // 1. Remove all non-digit characters (except the leading '+')
    let normalizedNumber = phoneNumber.replace(/[^0-9+]/g, '');

    // 2. Handle the leading '+' for international numbers
    const hasLeadingPlus = normalizedNumber.startsWith('+');
    if (hasLeadingPlus) {
        normalizedNumber = normalizedNumber.substring(1); // Remove the '+'
    }

    // 3. Check the length of the number (after removing formatting)
    const length = normalizedNumber.length;

    // Basic length checks - these are very general and not country-specific
    if (hasLeadingPlus) {
        // International number - typically longer
        return length >= 7 && length <= 15;
    } else {
        // North American style - could be 7 digits (local) or 10 digits (with area code)
        return length === 7 || length === 10;
    }
}

export const PhoneVerification = ({
    phoneNumber,
    setPhoneNumber,
    setIsPhoneVerified,
    isPhoneVerified
}) => {
    const dispatch = useDispatch();
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);

    const handleSendCode = async () => {
        try {
            setIsVerifying(true);
            const success = await dispatch(
                sendPhoneNumberVerificationCode(phoneNumber)
            );

            if (success) {
                setIsCodeSent(true);
                toast.success('Verification code sent successfully');
            } else {
                toast.error('Failed to send verification code');
            }
        } catch (err) {
            toast.error('An error occurred while sending verification code');
        } finally {
            setIsVerifying(false);
        }
    };

    const handleVerifyCode = async () => {
        try {
            setIsVerifying(true);
            const success = await dispatch(
                verifyPhoneNumber(phoneNumber, verificationCode)
            );

            if (success) {
                setIsPhoneVerified(true);
                toast.success('Phone number verified successfully');
            } else {
                toast.error('Invalid verification code');
            }
        } catch (err) {
            toast.error('An error occurred while verifying code');
        } finally {
            setIsVerifying(false);
        }
    };
    return (
        <div className="flex flex-row items-center mb-4 mt-2">
            {isPhoneVerified ? (
                <div className="flex items-center w-full gap-x-2">
                    <PhoneInput
                        value={phoneNumber}
                        disabled={true}
                        className="border-gray-400 w-full outline-none opacity-50"
                    />
                    <div className="bg-green-500 text-white px-4 py-2 rounded whitespace-nowrap">
                        Verified
                    </div>
                </div>
            ) : !isCodeSent ? (
                <div className="flex flex-row items-center w-full gap-x-2">
                    <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        className="border-gray-400 w-full outline-none"
                    />
                    <button
                        onClick={handleSendCode}
                        disabled={
                            isVerifying ||
                            !isValidPhoneNumber(phoneNumber || '')
                        }
                        className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50 whitespace-nowrap"
                    >
                        {isVerifying ? 'Sending...' : 'Verify'}
                    </button>
                </div>
            ) : (
                <div className="flex flex-row items-center w-full gap-x-2">
                    <input
                        type="text"
                        placeholder="Enter verification code"
                        value={verificationCode}
                        onChange={e => setVerificationCode(e.target.value)}
                        className="border-gray-400 px-4 py-2 w-full outline-none"
                    />
                    <button
                        onClick={handleVerifyCode}
                        disabled={isVerifying || !verificationCode}
                        className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50 whitespace-nowrap"
                    >
                        {isVerifying ? 'Verifying...' : 'Verify Code'}
                    </button>
                </div>
            )}
        </div>
    );
};
