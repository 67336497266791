import { motion, useInView } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import RadialGradient from '../Components/magicui/RadialGradient';
import { freeTrialActivated } from '../../constants';
import { prices } from './prices';
import PriceCard from './price-card';
import { PricingHeader } from './pricing-header';
import { IntervalSwitch } from './interval-switch';
import { useSelector } from 'react-redux';
import { selectAddOn } from '../../redux/reducers/enterprise/enterpriseOrganization';

type Interval = 'monthly' | 'yearly';

interface PricingSectionProps {
    subscriptionLinks: any;
    type: string;
    onlyCards: boolean;
    halfPage: boolean;
}

export default function PricingSection({
    subscriptionLinks,
    type,
    onlyCards,
    halfPage
}: PricingSectionProps) {
    const [interval, setInterval] = useState<Interval>('monthly');
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });
    const numPlans = type === 'enterprise' ? 1 : type === 'solo' ? 2 : 4;
    const addOn = useSelector(selectAddOn);

    // let gridClass = `mx-auto grid gap-4 ${numPlans === 4 ? 'lg:grid-cols-4' : numPlans === 1 ? 'lg:grid-cols-1' : ''}`;

    const [isHalfPage, setIsHalfPage] = useState(false);

    useEffect(() => {
        if (addOn) {
            setInterval('monthly');
        }
    }, [addOn]);

    const gridClass = `mx-auto grid gap-4 ${
        isHalfPage
            ? numPlans === 4
                ? 'lg:grid-cols-2'
                : numPlans === 1
                  ? 'lg:grid-cols-1'
                  : 'lg:grid-cols-1'
            : numPlans === 4
              ? 'lg:grid-cols-4'
              : numPlans === 1
                ? 'lg:grid-cols-1'
                : 'lg:grid-cols-2'
    }`;

    return (
        <section id="pricing" ref={ref}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ duration: 1 }}
                className="mx-auto flex max-w-screen-xl flex-col gap-8 px-4 py-8 md:px-8"
            >
                {!onlyCards && (
                    <>
                        <PricingHeader
                            freeTrialActivated={freeTrialActivated}
                        />
                    </>
                )}
                {!addOn && (
                    <IntervalSwitch
                        interval={interval}
                        setInterval={setInterval}
                        freeTrialActivated={freeTrialActivated}
                    />
                )}

                <div className={gridClass}>
                    {prices({
                        disabledBasic: type,
                        disabledSolo: type === 'enterprise',
                        disableEnterprrise: type === 'solo'
                    }).map(
                        (price, idx) =>
                            !price.disabled && (
                                <div key={price.id} className="w-full">
                                    <PriceCard
                                        price={price}
                                        interval={interval}
                                        isInView={isInView}
                                        idx={idx}
                                        freeTrialActivated={freeTrialActivated}
                                        subscriptionLinks={subscriptionLinks}
                                        type={type}
                                    />
                                </div>
                            )
                    )}
                </div>
            </motion.div>
        </section>
    );
}
