import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { salesCalendlyLink } from '../../../constants';
import { getWebsiteDomain } from '../../../auth_config';
import Widget from '../../WidgetPages/ChatWidgetPage/Widget';
import { useDispatch } from 'react-redux';
import { setAPIKey } from '../../../redux/reducers/enterprise_widget/enterpriseWidget';

export function Whitelabel() {
    const dispatch = useDispatch();
    const apiKey = process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY;
    dispatch(setAPIKey(apiKey));

    return (
        <Popover className="relative">
            <PopoverButton className="inline-flex bg-white rounded-full px-4 py-1 items-center gap-x-1 text-xs font-bold text-black hover:bg-gray-100 transition-colors">
                <span className="text-sm">Whitelabel</span>
                <ChevronDownIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-black"
                />
            </PopoverButton>

            <PopoverPanel
                transition
                className="fixed md:absolute left-0 mt-5 pb-5 transform sm:px-4 sm:px-0"
                style={{ zIndex: 5 }}
            >
                <div
                    style={{ maxHeight: 'calc(100vh - 160px)' }}
                    className="overflow-y-auto rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <WhitelabelWidget />
                </div>
            </PopoverPanel>
        </Popover>
    );
}

export const WhitelabelWidget = () => (
    <div className="relative bg-gray-800">
        <div className="flex flex-col items-center">
            <div className="flex flex-col space-y-4 p-4">
                <h3 className="text-xl font-bold text-white">
                    Whitelabel pre.dev's Scoping Widget
                </h3>
                <p className="text-gray-100 font-semibold text-md leading-snug border-l-4 border-blue-500 pl-4">
                    Embed the pre.dev widget on your website today with{' '}
                    <span className="text-yellow-300 font-bold underline">
                        just one line of code
                    </span>{' '}
                    and{' '}
                    <span className="text-green-300 font-bold">
                        deliver your scope of work
                    </span>{' '}
                    in just{' '}
                    <span className="text-green-300 font-bold">
                        one onboarding call
                    </span>
                    .
                </p>
            </div>
            <div className="flex h-[590px] w-[420px] max-w-[100vw] sm:mx-12 rounded-lg shadow-xl bg-white items-center justify-center">
                <Widget
                    apiKeySub={
                        process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY
                    }
                />
            </div>
            <div className="flex justify-center space-x-4 my-4">
                <Link
                    to="/enterprise/dashboard"
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                >
                    Get Started
                </Link>
                <a
                    href={salesCalendlyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                >
                    Contact Us
                </a>
            </div>
        </div>
    </div>
);

export default Whitelabel;
