import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import {
    ArrowRightOnRectangleIcon,
    ShareIcon,
    TrashIcon,
    GlobeAltIcon
} from '@heroicons/react/24/outline';
import { deleteProject } from '../../../../redux/actions/solo/deleteProject';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../../Components/Spinner';
import { useParams } from 'react-router-dom';
import { useSubscriptionPayment } from '../../../../context-providers/SubscriptionPayment';
import { LeadGenToggle } from './LeadGenToggle';

export const Actions = ({
    project,
    showShareLink,
    setProject,
    setOpen,
    shareLink
}) => {
    const dispatch = useDispatch();
    const { enterpriseOrganizationId } = useParams();
    const { subscription } = useSubscriptionPayment();

    const copyToClipboard = text => e => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        toast.info('Share Link Copied!');
    };

    const [deleting, setDeleting] = useState(false);

    const onDeleteProject = async id => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            setDeleting(true);
            await dispatch(deleteProject(id));
            setDeleting(false);
        }
    };

    const onTransferClick = async id => {
        setProject(id);
        setOpen(true);
    };

    let showToggle =
        enterpriseOrganizationId ===
            process.env.REACT_APP_PREDEV_ORGANIZATION_ID &&
        project.matchWithDev;

    return (
        <td className="px-2 py-3 text-center flex flex-col items-center justify-center font-medium gap-y-4">
            {project.businessURL && (
                <a
                    href={project.businessURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-x-2 text-blue-600 hover:text-blue-800 mb-2"
                >
                    <GlobeAltIcon className="h-5 w-5" />
                    <span className="text-sm truncate max-w-[200px]">
                        {project.businessURL.replace(/^https?:\/\//, '')}
                    </span>
                </a>
            )}
            <LeadGenToggle project={project} />
            {(!showToggle || showShareLink) && (
                <div
                    className={`flex items-center flex-row gap-x-4 transition-opacity duration-300 ${
                        !showShareLink ? 'opacity-0' : ''
                    }`}
                >
                    <button
                        onClick={copyToClipboard(shareLink(project))}
                        className="flex flex-row items-center cursor-pointer
                                    text-gray-600 text-left text-2xs hover:text-gray-900"
                    >
                        <ShareIcon className="h-6 mr-1" />
                    </button>

                    {subscription?.status === 'active' && (
                        <>
                            {!deleting ? (
                                <button
                                    onClick={() => onDeleteProject(project._id)}
                                    className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                >
                                    <TrashIcon className="h-6 mr-1" />
                                </button>
                            ) : (
                                <button
                                    className="flex flex-row items-center cursor-pointer
									text-red-600 text-left text-2xs hover:text-red-900"
                                >
                                    <Spinner w="5" />
                                </button>
                            )}

                            <button
                                onClick={() => onTransferClick(project)}
                                className="flex flex-row items-center cursor-pointer
									text-blue-600 text-left text-2xs hover:text-blue-900"
                            >
                                <ArrowRightOnRectangleIcon className="h-6 mr-1" />
                            </button>
                        </>
                    )}
                </div>
            )}
        </td>
    );
};
