import React, { createContext, useState, useEffect, useContext } from 'react';
import { client } from '../api/client';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { GET_USER_SUBSCRIPTION } from '../api/graphQL';
import { useEnterpriseOrganization } from './EnterpriseOrganization';
import { selectIsLeadGen } from '../redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { useSubscription } from '@apollo/client';
import {
    ENTERPRISE_SUBSCRIPTION_STATUS,
    USER_SUBSCRIPTION_STATUS
} from '../api/graphQL/solo/subscription';

const SubscriptionPaymentContext = createContext();

export function SubscriptionPaymentProvider({
    children,
    isChatWidget,
    isSolo,
    isShare,
    isHomePage,
    isEnterprise
}) {
    const { doesSessionExist, accessTokenPayload } = useSessionContext();
    const [soloSubscriptionPayment, setSubscriptionPayment] = useState({
        loading: true
    });
    let { enterpriseOrganization, enterpriseOrganizationId } =
        useEnterpriseOrganization();
    const [isSubbed, setIsSubbed] = useState(false);
    const [status, setStatus] = useState(null);
    const [tier, setTier] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const isLeadGen = useSelector(selectIsLeadGen);
    const [lastSubEnterpriseId, setLastSubEnterpriseId] = useState(null);
    const [lastSubUserId, setLastSubUserId] = useState(null);

    const setSub = sub => {
        if (isHomePage || isShare) {
            return;
        }
        setSubscription(sub);
        setStatus(sub?.status);
        setTier(sub?.tier);
        setIsSubbed(sub?.status === 'active');
    };

    useEffect(() => {
        if (isShare) {
            setSub(null);
        } else if (isHomePage) {
            setSub(null);
        } else if (isSolo) {
            if (accessTokenPayload?.userId !== lastSubUserId) {
                setSub(soloSubscriptionPayment);
            }
        } else if (isEnterprise || isChatWidget) {
            if (enterpriseOrganizationId !== lastSubEnterpriseId) {
                setSub(enterpriseOrganization?.subscriptionStatus);
            }
        } else {
            setSub(null);
        }
    }, [isShare, isSolo, soloSubscriptionPayment, enterpriseOrganization]);

    useEffect(() => {
        async function getSubscriptionPayment() {
            try {
                const { data } = await client.query({
                    query: GET_USER_SUBSCRIPTION,
                    fetchPolicy: 'network-only'
                });
                setSub(data.userSubscriptionStatus);
            } catch (error) {
                console.log({ error });
                setSub(false);
            }
        }
        if (doesSessionExist) {
            getSubscriptionPayment();
        } else {
            setSub(false);
        }
    }, [doesSessionExist, accessTokenPayload?.sessionHandle]);

    useSubscription(USER_SUBSCRIPTION_STATUS, {
        variables: { userId: accessTokenPayload?.userId },
        shouldResubscribe: true,
        skip: !isSolo,
        onData: data => {
            if (isSolo) {
                // console.log(
                //     'user subscription status',
                //     data.data.data.userSubscriptionStatusStream
                // );
                setSub(data.data.data.userSubscriptionStatusStream);
                setLastSubUserId(accessTokenPayload?.userId);
            }
        },
        onError: error => {
            console.error(JSON.stringify(error, null, 2));
        }
    });

    useSubscription(ENTERPRISE_SUBSCRIPTION_STATUS, {
        variables: { enterpriseOrganizationId },
        shouldResubscribe: true,
        skip: !isEnterprise,
        onData: data => {
            if (isEnterprise) {
                // console.log(
                //     'enterprise subscription status',
                //     data.data.data.enterpriseSubscriptionStatusStream
                // );
                setSub(data.data.data.enterpriseSubscriptionStatusStream);
                setLastSubEnterpriseId(enterpriseOrganizationId);
            }
        }
    });

    return (
        <SubscriptionPaymentContext.Provider
            value={{
                isSubbed,
                status,
                tier,
                subscription,
                isSolo,
                isShare,
                isEnterprise,
                isHomePage,
                isLeadGen
            }}
        >
            {children}
        </SubscriptionPaymentContext.Provider>
    );
}

export const useSubscriptionPayment = () =>
    useContext(SubscriptionPaymentContext);
